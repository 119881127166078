*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  background-color: #f2f2f2;
  color: #424242;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 10px;
  margin-top: 0;
}

h1 {
  font-size: 3.998em;
}
h2 {
  font-size: 2.827em;
}
h3 {
  font-size: 1.999em;
}
h4 {
  font-size: 1.414em;
}

.container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}